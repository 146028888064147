import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'

const DoveSiamoPage = ({ data: { markdownRemark: { frontmatter: { titolo, mappa, indirizzo, immagine, orari }, html } } }) => (
  <Layout title={titolo}>
    <div
      className="full-width-image-container margin-top-0"
      style={{
        backgroundImage: `url(${immagine.childImageSharp.fluid.src})`,
      }}
    >
      <h1
        className="has-text-weight-bold is-size-1 main-title"
        style={{
          color: '#2fbaff',
          padding: '1rem',
          backgroundColor: 'rgba(255, 255, 255, 0.8)'
        }}
      >
        {titolo}
      </h1>
    </div>
    <section className="section">
      <div className="container">
        <div className="content content-max">
          <h2>Dove siamo</h2>
          <p>{indirizzo}</p>
          <div className="container-gmaps" dangerouslySetInnerHTML={{ __html: mappa }} />
        </div>
      </div>
    </section>
  </Layout>
);

export default DoveSiamoPage

export const doveSiamoPageQuery = graphql`
  query doveSiamoPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        titolo
        mappa
        indirizzo
        immagine {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
